<template>
  <Breadcrumb />
  <div class="d-flex justify-content-end">
    <router-link to="New" class="btn btn-success btn-action-new-page"
      ><i class="bi bi-plus"></i>
      {{
        $t(
          "BusinessProcess.NewButton",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</router-link
    >
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    @onBpmnDesignerButtonClick="onBpmnDesignerButtonClick"
  />
  <ModalBPM :recordId="bpmDesignRecordId" />
</template>
<script>
import ModalBPM from "@/components/custom/BPM/Modal";
export default {
  name: "BusinessProcessList",
  components: {
    ModalBPM,
  },
  data() {
    return {
      bpmDesignRecordId: null,
      gridSettings: {
        action: "BusinessProcessList",
        requestUrl: String.format("/Brs-BusinessProcessList"),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "businessProcessId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        serialNoLink: `#/BusinessProcess/Edit/`,

        isGatewayRequest: true, //!! axios instance changed

        allowSearchPanel: false,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: false,
        allowDeleting: true,
        deleteActionUrl: "/BusinessProcess/Delete",
        autoOrderProcess: true,

        buttons: [
          {
            name: "bpmn",
            cssClass: "btn-primary",
            iconClass: "bi-columns-gap",
            routeButton: false,
            emitMethodName: "onBpmnDesignerButtonClick",
            attributes: {
              "data-bs-toggle": "modal",
              "data-bs-target": "#modalBPM",
            },
          },
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "BusinessProcessEdit",
              params: {
                businessProcessId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "BusinessProcess.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BusinessProcess.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BusinessProcess.Description",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "description",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },

        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    onBpmnDesignerButtonClick(rowData) {
      this.bpmDesignRecordId = rowData.publicId;
    },
  },
};
</script>
