<template>
  <div
    class="modal fade"
    id="modalBPM"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="modalBPMLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <h5 class="modal-title" id="modalBPMLabel">
            <i class="bi bi-columns-gap"></i>
            {{
              $t(
                "Components.BpmModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            v-if="!this.buttonDisabled"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="errors.length > 0" class="alert alert-warning">
            <ul class="mb-0">
              <li v-for="error in errors" v-bind:key="error">
                {{ error }}
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div
                class="bg-light d-flex justify-content-between p-2 mb-3 border"
              >
                <div>
                  <div class="btn-group ms-0">
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="Basic outlined example"
                    >
                      <button
                        type="button"
                        class="btn btn-outline-primary pe-2"
                        @click="processUndo()"
                      >
                        <i class="bi bi-arrow-counterclockwise"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-primary pe-2"
                        @click="processRedo()"
                      >
                        <i class="bi bi-arrow-clockwise"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-danger pe-2"
                        @click="processRestart()"
                      >
                        <i class="bi bi-trash"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-primary pe-2"
                        @click="processZoomIn()"
                      >
                        <i class="bi bi-zoom-in"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-primary pe-2"
                        @click="processZoomOut()"
                      >
                        <i class="bi bi-zoom-out"></i>
                      </button>
                    </div>
                  </div>
                  <div class="btn-group ms-3">
                    <button
                      type="button"
                      class="btn btn-outline-danger pe-2"
                      @click="processReZoom()"
                    >
                      ReZoom
                    </button>
                  </div>
                  <div class="btn-group ms-3">
                    <button
                      type="button"
                      class="btn btn-outline-warning"
                      @click="$refs.refFile.click()"
                    >
                      <i class="bi bi-folder2-open"></i> Import
                    </button>
                  </div>
                  <div class="btn-group ms-3">
                    <button
                      type="button"
                      class="btn btn-outline-success dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Download
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" @click="downloadProcessAsXml()"
                          >XML</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" @click="downloadProcessAsSvg()"
                          >SVG</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          @click="downloadProcessAsBpmn()"
                          >BPMN</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-if="errors.length == 0"
                    :disabled="this.buttonDisabled"
                    @click="updateBusinessProcessDesign()"
                  >
                    <span
                      v-if="this.buttonDisabled"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {{
                      $t(
                        "Components.PageDesignerModal.SaveChanges",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row h-100">
            <div class="col-md-9" ref="canvas"></div>
            <div class="col-md-3" id="properties"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <input
    type="file"
    id="files"
    ref="refFile"
    style="display: none"
    accept=".xml, .bpmn"
    @change="importLocalFile"
  />
</template>
<script>
import "@/assets/css/bpm.css";
import BpmnModeler from "bpmn-js/lib/Modeler";
import DefaultEmptyXML from "@/assets/js/defaultEmpty";
import { createToast } from "mosha-vue-toastify";
import {
  BpmnPropertiesPanelModule,
  BpmnPropertiesProviderModule,
} from "bpmn-js-properties-panel";
export default {
  name: "BpmModal",
  data() {
    return {
      businessProcessData: [],
      bpmnModeler: null,
      additionalModel: {},
      errors: [],
      buttonDisabled: false,
      defaultZoom: 1,
      recoverable: false,
      revocable: false,
      options: {
        type: Object,
        default: () => ({}),
      },
    };
  },
  props: ["recordId"],
  components: {},
  methods: {
    updateBusinessProcessDesign() {
      var self = this;
      this.bpmnModeler.saveXML({ format: true }, function (err, xml) {
        if (!err) {
          self.buttonDisabled = true;
          self.businessProcessData.design = xml;
          self.$prodGatewayAxios
            .post("/Brs-BusinessProcessUpdate", { ...self.businessProcessData })
            .then((r) => {
              var response = r.data;
              self.buttonDisabled = false;
              if (!response.isOk) {
                self.errors.push(r.msg);
                createToast(
                  self.$t(
                    "Messages.ErrorMesage",
                    {},
                    { locale: self.$store.state.activeLang }
                  ),
                  {
                    showIcon: "true",
                    position: "top-right",
                    type: "danger",
                    transition: "zoom",
                  }
                );
              } else {
                createToast(
                  self.$t(
                    "Messages.SuccessMessage",
                    {},
                    { locale: self.$store.state.activeLang }
                  ),
                  {
                    showIcon: "true",
                    position: "top-right",
                    type: "success",
                    transition: "zoom",
                  }
                );
              }
            })
            .catch((r) => {
              self.errors.push(r);
            });
        } else {
          console.error(`[Process Designer Warn ]: ${err.message || err}`);
        }
      });
    },
    initBpmnModeler() {
      if (this.bpmnModeler) return;
      this.bpmnModeler = new BpmnModeler({
        container: this.$refs["canvas"],
        // keyboard: this.keyboard ? { bindTo: document } : null,
        additionalModules: this.additionalModules,
        propertiesPanel: {
          parent: "#properties",
        },
        moddleExtensions: this.moddleExtensions,
        ...this.options,
      });
      this.$emit("init-finished", this.bpmnModeler);
      this.initModelListeners();
    },
    initModelListeners() {
      //
    },
    downloadProcessAsXml() {
      this.downloadProcess("xml");
    },
    downloadProcessAsBpmn() {
      this.downloadProcess("bpmn");
    },
    downloadProcessAsSvg() {
      this.downloadProcess("svg");
    },
    setEncoded(type, filename = "diagram", data) {
      const encodedData = encodeURIComponent(data);
      return {
        filename: `${filename}.${type}`,
        href: `data:application/${
          type === "svg" ? "text/xml" : "bpmn20-xml"
        };charset=UTF-8,${encodedData}`,
        data: data,
      };
    },
    downloadProcess(type, name) {
      try {
        const _this = this;
        if (type === "xml" || type === "bpmn") {
          _this.bpmnModeler.saveXML({ format: true }, function (err, xml) {
            if (!err) {
              let { href, filename } = _this.setEncoded(
                type.toUpperCase(),
                name,
                xml
              );
              _this.downloadFunc(href, filename);
            } else {
              console.error(`[Process Designer Warn ]: ${err.message || err}`);
            }
          });
        } else {
          _this.bpmnModeler.saveSVG({ format: true }, function (err, svg) {
            if (!err) {
              let { href, filename } = _this.setEncoded(
                type.toUpperCase(),
                name,
                svg
              );
              _this.downloadFunc(href, filename);
            } else {
              console.error(`[Process Designer Warn ]: ${err.message || err}`);
            }
          });
        }
      } catch (e) {
        console.error(`[Process Designer Warn ]: ${e.message || e}`);
      }
    },
    downloadFunc(href, filename) {
      if (href && filename) {
        let a = document.createElement("a");
        a.download = filename;
        a.href = href;
        a.click();
        URL.revokeObjectURL(a.href);
      }
    },
    createNewDiagram(xml) {
      //TODO:burada bir hata var. bakılıcak.
      //debugger;
      let newId = `Process_${new Date().getTime()}`;
      let newName = `SetXRM_BPM_${new Date().getTime()}`;
      let xmlString = xml || DefaultEmptyXML(newId, newName, "camunda");
      try {
        let { warnings } = this.bpmnModeler.importXML(xmlString);
        if (warnings && warnings.length) {
          warnings.forEach((warn) => console.warn(warn));
        }
      } catch (e) {
        console.error(`[Process Designer Warn]: ${e?.message || e}`);
      }
    },
    importLocalFile() {
      const that = this;
      const file = this.$refs.refFile.files[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function () {
        let xmlStr = this.result;
        that.createNewDiagram(xmlStr);
      };
    },
    processRedo() {
      this.bpmnModeler.get("commandStack").redo();
    },
    processUndo() {
      this.bpmnModeler.get("commandStack").undo();
    },
    processZoomIn(zoomStep = 0.1) {
      let newZoom = Math.floor(this.defaultZoom * 100 + zoomStep * 100) / 100;
      if (newZoom > 4) {
        throw new Error(
          "[Process Designer Warn ]: The zoom ratio cannot be greater than 4"
        );
      }
      this.defaultZoom = newZoom;
      this.bpmnModeler.get("canvas").zoom(this.defaultZoom);
    },
    processZoomOut(zoomStep = 0.1) {
      let newZoom = Math.floor(this.defaultZoom * 100 - zoomStep * 100) / 100;
      if (newZoom < 0.2) {
        throw new Error(
          "[Process Designer Warn ]: The zoom ratio cannot be less than 0.2"
        );
      }
      this.defaultZoom = newZoom;
      this.bpmnModeler.get("canvas").zoom(this.defaultZoom);
    },
    processReZoom() {
      this.defaultZoom = 1;
      this.bpmnModeler.get("canvas").zoom("fit-viewport", "auto");
    },
    processRestart() {
      this.recoverable = false;
      this.revocable = false;
      this.createNewDiagram(null);
    },
    getBusinessProcess() {
      this.$prodGatewayAxios
        .get(String.format("/Brs-BusinessProcessGet?id={0}", this.recordId))
        .then((response) => {
          this.businessProcessData = response.data;

          var designXML = response.data.design;
          if (!String.isNullOrWhiteSpace(designXML)) {
            this.createNewDiagram(designXML);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  computed: {
    additionalModules() {
      const modules = [];

      if (
        Object.prototype.toString.call(this.additionalModel) ===
        "[object Array]"
      ) {
        modules.push(...this.additionalModel);
      } else {
        this.additionalModel && modules.push(this.additionalModel);
      }

      // todo
      // const TranslateModule = {
      //   translate: ["value", customTranslate(this.translations || translationsCN)]
      // };
      // modules.push(TranslateModule);

      modules.push(BpmnPropertiesPanelModule);
      modules.push(BpmnPropertiesProviderModule);

      return modules;
    },
    moddleExtensions() {
      const extensions = {};
      return extensions;
    },
  },
  watch: {
    recordId: function () {
      this.createNewDiagram(null);
      this.getBusinessProcess();
      this.errors = [];
    },
  },
  mounted() {
    this.initBpmnModeler();
  },
  beforeUnmount() {
    if (this.bpmnModeler) this.bpmnModeler.destroy();
    this.$emit("destroy", this.bpmnModeler);
    this.bpmnModeler = null;
  },
};
</script>
